//import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
//import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Header = ({ siteTitle }) => {
  /* const data = useStaticQuery(graphql`
    query {
      allStrapiLandingPageHomeSettings {
        edges {
          node {
            site_name
          }
        }
      }
    }
  `);  */

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid" style={{display: "inline-flex", width:"100%"}}>
        <a className="navbar-brand" href="/" aria-label={siteTitle}>
        <StaticImage
          src="../../images/Redhill-logo.png"
          width={161}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Redhill"
        /></a>

        {/* <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <a className="nav-link" href="#our-presence">
              ÜBER UNS 
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
              UNSER ANGEBOT   
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                href="#contact"
              >
                KONTAKT
              </a>
            </li>
          </ul>
        </div> */}
        </div>
      </nav>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
